.waitingscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;

    background-color: var(--dark-2);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span, a {
        font-weight: 800;
    }
}