.loadinganimation {
    height: 100%;
    aspect-ratio: 2/1;

    --dot-width: 25%;

    .dot {
        position: absolute;
        bottom: 0;
        height: 50%;
        width: 25%;
        border-radius: 50%;
        background-color: var(--accent);

        animation-duration: 1.3s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        animation-direction: normal;
    }

    :nth-child(1) {
        left: 0;
        animation-name: bump1;
    }
    :nth-child(2) {
        left: calc(50% - var(--dot-width)  /2);
        animation-name: bump2;
    }
    :nth-child(3) {
        left: calc(100% - var(--dot-width) );
        animation-name: bump3;
    }

    @keyframes bump1{
        0% {
            bottom: 0%;
        }
        25% {
            bottom: 50%;
        }
        50% {
            bottom: 0%;
        }
    }
    @keyframes bump2{
        25% {
            bottom: 0%;
        }
        50% {
            bottom: 50%;
        }
        75% {
            bottom: 0%;
        }
    }
    @keyframes bump3{
        50% {
            bottom: 0%;
        }
        75% {
            bottom: 50%;
        }
        100% {
            bottom: 0%;
        }
    }
}