.player {
    background-color: var(--gray-2);
    border-radius: 16px;
    padding: 16px 24px;
    min-width: 280px;
    height: fit-content;

    & h2 {
        margin-bottom: 16px !important;
    }
}