
:root {
    --accent: #8620AA;
    --accent-darker: #661881;

    --gray-1: #66586C;
    --gray-2: #6A5172;
    --dark-1: #302933;
    --dark-2: #181419;

    --light-1: #DED5E1;
    --light-2: #ffffff;
}

@font-face {
    font-family: 'Marianne';
    src: url('./fonts/Marianne/Marianne-Regular.woff2') format('woff2');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Marianne';
    src: url('./fonts/Marianne/Marianne-Bold.woff2') format('woff2');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'Marianne';
    src: url('./fonts/Marianne/Marianne-ExtraBold.woff2') format('woff2');
    font-style: normal;
    font-weight: 800;
}

body {
    // ensure that the body fills the entire viewport
    min-height: 100dvh;
    margin: 0;

    background-color: var(--dark-2);
    color: var(--light-2)
}

* {
    box-sizing: border-box;
    position: relative;
    min-width: 0;

    font-family: 'Marianne', sans-serif;
    font-display: fallback;
    font-weight: 700;
}

h1, h2, h3, h4 {
    text-wrap: balance;
}

h1 {
    font-weight: 800;
    * {
        font-weight: 800;
    }
}

h2, h2 * {
    font-weight: 800;
}

h3 {
    font-weight: 700;
    font-size: 16px;
}

p {
    text-wrap: pretty;
}

a {
    color: var(--accent);
    text-decoration: none;
}

.maincontent {
    height: 100vh;
    width: 100vw;
    padding: 5% 7.5%;

    & > .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 32px;
    
        & > div {
            padding: 16px 32px;
            background-color: var(--dark-1);
            border-radius: 32px;
        }
        
        h1, h2, h3 {
            margin: 0;
        }
    }
}

.altcolor {
    color: var(--light-1);
}