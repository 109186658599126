.settings {
    & .setting {
        width: 256px;
    }

    & h2 {
        margin-bottom: 8px !important;
    }
    & h3 {
        margin-bottom: 4px !important;
    }

    & > .container {
        margin: 0 16px;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }
}