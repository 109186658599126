.playerlist {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 16px;

    &.none {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    & > .container {
        height: 100%;
        gap: 16px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        overflow-y: auto;
    }
}