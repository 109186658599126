.slidervolume {
    height: 32px;
    border-radius: 16px;
    padding: 0 12px;

    display: flex;
    align-items: center;

    background-color: var(--dark-1);
    &.altcolor {
        background-color: var(--gray-2);
    }
}