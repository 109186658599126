.slider {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;

    background-image: linear-gradient(
        rgba(0,0,0,0) 0% 33%,
        var(--light-1) 33% 66%,
        rgba(0,0,0,0)  66% 100%);
    cursor: pointer;
    height: 16px;
    
    &::-moz-range-thumb {
        -webkit-appearance: none; 
        appearance: none;
        background-color: var(--light-1);
        border: none;
        border-radius: 0;
        height: 16px;
        width: 4px;
    }
    &::-webkit-slider-thumb { 
        -webkit-appearance: none; 
        appearance: none;
        background-color: var(--light-1);
        border: none;
        border-radius: 0;
        height: 16px;
        width: 4px;
    }
}
