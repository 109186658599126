.select {
    & > img {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 8px;
        top: calc(50% - 24px / 2);
        z-index: 1;
        cursor: pointer;
        pointer-events:none;
    }

    & > select {
        height: 32px;
        border-radius: 16px;
        border-width: 0;
        padding-left: 12px;
        padding-right: 36px;
        width: 100%;
        cursor: pointer;
        text-overflow: ellipsis;
    
        // remove default chevron
        appearance: none;
    
        background-color: var(--dark-1);
        &.altcolor {
            background-color: var(--gray-2);
        }
    }
}